



























































































































import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';
import {
  deleteDetail,
} from '@/serve/evaluatingBefore/evaluatingStandard';
import { getTenantData } from '@/serve/medicalAppointment/institutionManage';

import { getList } from '@/serve/evaluatingBefore/evaluatingQuestion';


interface DiseaseStandardList {
  comment: string;
  highestScore: number | string;
  lowestScore: number | string;
  remark: string;
  riskLevel: string;
  [index: string]: any;
}
interface StandarData {
  size?: number;
  current?: number;
  diseaseName?: string;
  [propName: string]: any;
}
@Component({
  name: 'EvaluatingStandard',
  filters: {},
})
export default class EvaluatingStandard extends Vue {
  public currentPage4 = 1;

  public formLabelWidth = '120px';
  public form = {
    illness: '',
    category: '',
  };

  public checkSignId = 0;



  public activeName = '低';






  public tableData = [];
  public switchValue = false
  public dialogVisible = false
  public answerRecommendation = ""


  public formDatas = {
    size: 10,
    current: 1,
    name: '',
    phone: '',
    type: 1
  };
  public tenantName = '';
  public restaurants = [];
  public isAdmin = sessionStorage.getItem('isAdmin');
  public created() {
    this.getDiseaseList();
  }
  public mounted() {
    this.getTenantData();
  }
  // 进入页面获取数据
  public async getDiseaseList() {

    const res = await getList(this.formDatas);
    const tableDataNew = res.data
    if (tableDataNew.records.length) {
      tableDataNew.records.map((item: { answerFirst: any; answerSecond: any; answerThird: any }) => {
        item.answerFirst = JSON.parse(item.answerFirst)
        item.answerSecond = JSON.parse(item.answerSecond)
        item.answerThird = JSON.parse(item.answerThird)

      })
    }
    this.tableData = tableDataNew;



    console.log("this.tableData", this.tableData);

  }

  public switchChange(e: any) {

    if (e) {
      this.formDatas.type = 2;
      this.getDiseaseList();
    } else {
      this.formDatas.type = 1;
      this.getDiseaseList();
    }

  }
  public editRow(e: any) {
    this.dialogVisible = true
    
    this.answerRecommendation = e
  }


  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
  

  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  // 筛选按钮
  public onSubmit(): void {
    this.getDiseaseList();
  }

  // 删除按钮
  public deleteRow(row: any, id: any): void {
    console.log(row);

    (this as any).$messageBox
      .confirm(
        `您确定是否删除疾病系统为${row.diseaseName}的这一条数据`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
      .then(async () => {
        const res = await deleteDetail(id);
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          this.getDiseaseList();
        }
      });
  }

  // 点击左右箭头
  public handleSizeChange(val: any): void {
    this.formDatas.size = val;
    this.getDiseaseList();
  }
  // 点击页码
  public handleCurrentChange(val: any): void {
    this.formDatas.current = val;
    this.getDiseaseList();
  }
  // 重置按钮
  public resetForm(formName: string) {
    (this.$refs[formName] as ElForm).resetFields();

    this.formDatas.name = '';
    this.formDatas.phone = '';
    this.getDiseaseList();
  }
  public handleClick() {
    console.log(1);
  }
  
}
